import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import "./assets/css/wave-bsb.css";
import companyLogo from "./assets/img/branding/logo.png";
import aboutUs from "./assets/img/about/about.jpg";
import "primeicons/primeicons.css";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phonr: "",
    message: "",
  });
  const [confirmMessage, setConfirmMessage] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, phone, message } = data;
    const payload = { first_name, last_name, email, phone, message };

    try {
      const response = await axios
        .post(
          "https://beta-app-backend.orendafinserv.com/api/v1/auth/contact-us?lang=ko",
          payload
        )
        .then((response) => {
          console.log(response);
          setConfirmMessage(true);
        });

      if (response.ok) {
        // Handle success
        console.log("Form data sent successfully");
      } else {
        // Handle error
        console.error("Failed to send form data");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  return (
    <div>
      {/* Required Meta Tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Document Title, Description, and Author */}
      <title>Orenda Finserv</title>
      <meta
        name="description"
        content="Wave is a Bootstrap 5 One Page Template."
      />
      <meta name="author" content="BootstrapBrain" />
      {/* Favicon and Touch Icons */}
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="./assets/img/branding/logo.svg"
      />
      {/* Google Fonts Files */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Satisfy&display=swap"
        rel="stylesheet"
      />
      {/* CSS Files */}
      <script src="https://unpkg.com/jquery@3.6.1/dist/jquery.min.js"></script>
      <script src="https://unpkg.com/isotope-layout@3.0.6/dist/isotope.pkgd.min.js"></script>
      <script src="https://unpkg.com/isotope-packery@2.0.1/packery-mode.pkgd.min.js"></script>
      <script src="https://unpkg.com/imagesloaded@5.0.0/imagesloaded.pkgd.min.js"></script>
      <script src="https://unpkg.com/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"></script>

      {/* <script src={project2}></script>
  <script src={wavebsb}></script> */}
      <link rel="stylesheet" href="./assets/css/wave-bsb.css" />
      {/* BSB Head */}
      {/* Header */}
      <header
        id="header"
        className="sticky-top bsb-tpl-header-sticky bsb-tpl-header-sticky-animationX"
      >
        {/* Navbar 1 - Bootstrap Brain Component */}
        <nav
          id="scrollspyNav"
          className="navbar navbar-expand-lg bsb-tpl-bg-blue bsb-navbar bsb-navbar-hover bsb-navbar-caret bsb-tpl-navbar-sticky"
          data-bsb-sticky-target="#header"
        >
          <div className="container">
            <a className="navbar-brand" href="index.html">
              <img src={companyLogo} className="bsb-tpl-logo" alt="" />
            </a>
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex={-1}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  Menu
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body">
                <ul
                  id="bsb-tpl-navbar"
                  className="navbar-nav justify-content-end flex-grow-1"
                >
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.hash === "#scrollspyHero" ? "active" : ""
                      }`}
                      href="#scrollspyHero"
                      aria-current="page"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* <a class="nav-link" href="#scrollspyServices" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar">Services</a> */}
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.hash === "#scrollspyPortfolio" ? "active" : ""
                      }`}
                      href="#scrollspyPortfolio"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.hash === "#scrollspyAbout" ? "active" : ""
                      }`}
                      href="#scrollspyAbout"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* <a class="nav-link" href="#scrollspyTeam" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar">Team</a> */}
                  </li>
                  <li className="nav-item">
                    {/* <a class="nav-link" href="#scrollspyPricing" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar">Pricing</a> */}
                  </li>
                  <li className="nav-item">
                    {/* <a class="nav-link" href="#scrollspyBlog" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar">Blog</a> */}
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.hash === "#scrollspyContact" ? "active" : ""
                      }`}
                      href="#scrollspyContact"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link `}
                      href="https://beta.partner.orendafinserv.com"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                    >
                      Partner Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Hero 2 - Bootstrap Brain Component */}
      <section
        id="scrollspyHero"
        className="bsb-hero-2 bsb-tpl-bg-blue py-5 py-xl-8 py-xxl-10"
      >
        <div className="container overflow-hidden">
          <div className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between">
            <div className="col-12 col-lg-6 order-1 order-lg-0">
              <h1 className="display-3 fw-bolder mb-3">
                Optimize Your <br /> Finances
              </h1>
              <p className="fs-4 mb-5">
                Explore our AI-powered marketplace for optimized financial deals
                that fit your unique needs.
              </p>
              <div className="d-grid gap-2 d-sm-flex">
                <a href="/commingsoon">
                  <button
                    type="button"
                    className="btn btn-primary bsb-btn-3xl rounded-pill"
                  >
                    Get Started{" "}
                  </button>
                </a>
                {/* <button type="button" class="btn btn-outline-primary bsb-btn-3xl rounded-pill">Buy Credits</button> */}
              </div>
            </div>
            <div className="col-12 col-lg-5 text-center">
              <img
                className="img-fluid"
                loading="lazy"
                src="./assets/img/hero/hero-home.jpg"
                alt=""
                style={{
                  WebkitMaskImage: "url(./assets/img/hero/hero-blob-1.svg)",
                  maskImage: "url(./assets/img/hero/hero-blob-1.svg)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* Main */}
      <main id="main">
        {/* Section - Services */}
        {/* Service 3 - Bootstrap Brain Component */}
        <section id="scrollspyServices" className=" py-xl-8  py-xxl-10">
          <div className="container mb-5 mb-md-6 mb-xl-10">
            <div className="row justify-content-md-center">
              <div className="col-12  text-center">
                {/* <h2 class="display-3 fw-bolder mb-4">You will get the <br>perfect <mark class="bsb-tpl-highlight bsb-tpl-highlight-yellow"><span class="bsb-tpl-font-hw display-1 text-accent fw-normal">resolutions</span></mark> with our proficient services.</h2> */}
                <p className="fs-4 ">
                  At Orenda, we've redefined the financial marketplace through
                  the power of AI and Data Analysis. Our user-friendly
                  marketplace journey begins with a straightforward
                  questionnaire, which our advanced technology uses to analyze
                  individual financial needs. We then connect this personalized
                  data with a wide spectrum of financial providers, presenting
                  users with a tailored marketplace of deals. Privacy and
                  security are our top priorities. Our mission is to create a
                  secure, personalized, and accessible financial marketplace.
                  With our platform, individuals gain access to a marketplace
                  that simplifies financial solutions, saving time and effort
                  while making well-informed decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="container overflow-hidden">
            <div className="row gy-5 gx-md-4 gy-lg-0 gx-xxl-5 justify-content-center">
              <div className="col-11 col-sm-6 col-lg-3"></div>
              <div className="col-11 col-sm-6 col-lg-3">
                <div className="badge bsb-tpl-bg-green text-primary p-3 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    fill="currentColor"
                    className="bi bi-aspect-ratio"
                    viewBox="0 0 512 512"
                  >
                    !Font Awesome Free 6.5.1 by @fontawesome -
                    https://fontawesome.com License -
                    https://fontawesome.com/license/free Copyright 2024
                    Fonticons, Inc. --&gt;
                    <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                  </svg>
                </div>
                <h2 className="mb-3">10+ years of experience</h2>
                {/* <p class="mb-3 text-secondary">We can help you to create a visually appealing and user-friendly website. We take into account your brand identity and target audience when designing your website.</p> */}
              </div>
              <div className="col-11 col-sm-6 col-lg-3">
                <div className="badge bsb-tpl-bg-pink text-primary p-3 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    fill="currentColor"
                    viewBox="0 0 576 512"
                  >
                    {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                  </svg>
                </div>
                <h2 className="mb-3">AI-Driven Financial Advice</h2>
                {/* <p class="mb-3 text-secondary">We can help you to improve your website's visibility in search engine results pages (SERPs). This can lead to more traffic to your website and more conversions.</p>
        <a href="#!" class="fw-bold text-decoration-none link-primary">
          Learn More
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
          </svg>
        </a> */}
              </div>
              <div className="col-11 col-sm-6 col-lg-3"></div>
            </div>
          </div>
        </section>
        {/* Call To Action 1 - Bootstrap Brain Component */}
        {/* <section class="bsb-cta-1 px-2 bsb-overlay" style="background-image: url('./assets/img/cta/cta-img-1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
        <h3 class="fs-5 mb-3 text-white text-uppercase"><mark class="text-white bsb-tpl-highlight">Unlock Fresh Prospects</mark></h3>
        <h2 class="display-3 text-white fw-bolder mb-4 pe-xl-5">We are a design agency studio delivering custom creative & unique websites.</h2>
        <a href="#!" class="btn btn-accent bsb-btn-3xl rounded mb-0 text-nowrap">Join Us</a>
      </div>
    </div>
  </div>
</section> */}
        {/* Section - Portfolio */}
        {/* Project 2 - Bootstrap Brain Component */}
        <section
          id="scrollspyPortfolio"
          className="bsb-hero-2 bsb-tpl-bg-blue py-5 py-xl-8 bsb-section-py-xxl-1"
        >
          <div className="container mb-5 mb-md-6 mb-xl-10">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h2 className="display-3 fw-bolder ">
                  Marketplace for below products
                </h2>
              </div>
            </div>
          </div>
          <div className="container pt-1">
            <div className="row gy-4">
              <div className="col-12 col-sm-6 col-xl-3">
                <div className="card border-0 border-bottom border-primary shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <h3 className="mb-3">Personal Loans</h3>
                    <p className="fs-5 mb-0 text-secondary">
                      For salaried individuals, select the most cost-effective
                      financer for your unsecured personal loan requirement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-xl-3">
                <div className="card border-0 border-bottom border-primary shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <h3 className="mb-3">Unsecured Business Loans</h3>
                    <p className="fs-5 mb-0 text-secondary">
                      Fast &amp; Flexible financing for your business. No
                      Collateral required.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-xl-3">
                <div className="card border-0 border-bottom border-primary shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <h3 className="mb-3">Working Capital Financing</h3>
                    <p className="fs-5 mb-0 text-secondary">
                      Optimize your day-to-day operations with hassle-free
                      working capital solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-xl-3">
                <div className="card border-0 border-bottom border-primary shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <h3 className="mb-3">Project Loans</h3>
                    <p className="fs-5 mb-0 text-secondary">
                      Turn your visions into reality with our tailored project
                      funding options.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-xl-3">
                <div className="card border-0 border-bottom border-primary shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <h3 className="mb-3">Corporate Funding</h3>
                    <p className="fs-5 mb-0 text-secondary">
                      For Large Enterprises with turnover above 500 Crores,
                      empower your enterprise with strategic financial backing
                      for growth and expansion. Can be funded without collateral
                      coverage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container overflow-hidden">
            <div className="row gy-2 g-md-2 g-xl-3 bsb-project-2-grid"></div>
          </div>
        </section>
        {/* Section - About */}
        <section
          id="scrollspyAbout"
          className="bsb-tpl-bg-alice-blue py-5 py-xl-8 bsb-section-py-xxl-1"
        >
          {/* FAQ 1 - Bootstrap Brain Component */}
          <div className="container">
            <div className="row gy-5 gy-lg-0 align-items-lg-center">
              <div className="col-12 col-lg-6">
                <img
                  className="img-fluid rounded"
                  loading="lazy"
                  src={aboutUs}
                  alt=""
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="row justify-content-xl-end">
                  <div className="col-12 col-xl-11">
                    <h2 className="display-3 fw-bolder mb-4">About Us</h2>
                    <p className="fs-4 mb-5">
                      Led by a team of Chartered Accountants, Orenda is a
                      leading financial management and consulting firm based in
                      India. With our expertise and experience, we provide
                      comprehensive financial solutions to businesses and
                      individuals, helping them achieve their financial goals.
                      Our team of highly qualified professionals is dedicated to
                      delivering exceptional services tailored to meet the
                      unique needs of our clients. At Orenda, we are committed
                      to excellence and strive to build long-term relationships
                      based on trust and integrity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fact 1 - Bootstrap Brain Component */}
        </section>
        {/* Section - Team */}
        {/* Team 1 - Bootstrap Brain Component */}
        <section
          id="scrollspyTeam"
          className="py-5  py-xl-8 bsb-section-py-xxl-1"
        >
          <div className="container mb-5 mb-md-6 mb-xl-10">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h3 className="display-4 fw-bolder mb-4">Our Reach</h3>
              </div>
            </div>
          </div>
          <div className="container overflow-hidden">
            <div className="row gy-4 gy-lg-0 gx-xxl-5">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                  <div className="card-body p-0">
                    <figure className="m-0 p-0">
                      <figcaption className="m-0 p-4">
                        <h4 className="mb-1">Rajkot</h4>
                        <p class="text-secondary mb-0">
                          Office No. 1226-27, RK World Tower, 150 Feet Ring Rd,
                          Sheetal Park, Rajkot - 360005
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                  <div className="card-body p-0">
                    <figure className="m-0 p-0">
                      <figcaption className="m-0 p-4">
                        <h4 className="mb-1">Morbi</h4>
                        <p class="text-secondary mb-0">
                          Empire 36, 3rd Floor, Shop No 312, Mahendranagar
                          Chowkdi, Morbi - 363642
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                  <div className="card-body p-0">
                    <figure className="m-0 p-0">
                      <figcaption className="m-0 p-4">
                        <h4 className="mb-1">Gandhidham</h4>
                        <p class="text-secondary mb-0">
                          Office No. 8, Rajdeep Complex, First Floor, Opp. Bank
                          of Baroda, Banking Circle, Gandhidham - 370201
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                  <div className="card-body p-0">
                    <figure className="m-0 p-0">
                      <figcaption className="m-0 p-4">
                        <h4 className="mb-1">Ahmedabad</h4>
                        <p class="text-secondary mb-0">
                          C702, PNTC, Near Titanium City Center, Radio Mirchi
                          Road, Prahaladnagar, Ahmedabad - 380051
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section - Pricing */}
        {/* Pricing 1 - Bootstrap Brain Component */}
        {/* Section - Testimonial */}
        {/* Testimonial 3 - Bootstrap Brain Component */}
        {/* Section - Blog */}
        {/* Blog 2 - Bootstrap Brain Component */}
        {/* Section - Contact */}
        {/* Contact 2 - Bootstrap Brain Component */}
        <section
          id="scrollspyContact"
          className="py-5 bsb-tpl-bg-linen  py-xl-8 bsb-section-py-xxl-1"
        >
          <div className="container">
            <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
              <div className="col-12  col-lg-6">
                {/* <h1>Contact Form</h1> */}
                {!confirmMessage && (
                  <form
                    onSubmit={handleSubmit}
                    id="contact_form"
                    name="contact_form"
                  >
                    <div className="mb-5 row">
                      <div className="col">
                        <label>First Name</label>
                        <input
                          type="text"
                          required
                          maxLength={50}
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col">
                        <label>Last Name</label>
                        <input
                          type="text"
                          required
                          maxLength={50}
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mb-5 row">
                      <div className="col">
                        <label htmlFor="email_addr">Email address</label>
                        <input
                          type="email"
                          required
                          maxLength={50}
                          className="form-control"
                          id="email_addr"
                          name="email"
                          placeholder="name@example.com"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="phone_input">Phone</label>
                        <input
                          type="tel"
                          required
                          maxLength={50}
                          className="form-control"
                          id="phone_input"
                          name="phone"
                          placeholder="Phone"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mb-5">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows={5}
                        defaultValue={""}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary px-4 btn-lg"
                    >
                      Submit
                    </button>
                  </form>
                )}
                {confirmMessage && (
                  <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0">
                      {" "}
                      <i
                        className="pi pi-check-circle"
                        style={{
                          color: "green",
                          fontSize: "1.7rem",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      ></i>{" "}
                      Your message has been sent Successfully !{" "}
                    </p>
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <div className="row justify-content-xl-center">
                  <div className="col-12 col-xl-11">
                    <h2 className="h1 mb-3">Get in touch</h2>
                    <p className="lead fs-4 text-secondary mb-5">
                      Complete the form below to get in touch with us. We are
                      here to provide expert financial management and consulting
                      services tailored to your needs.
                    </p>
                    <div className="d-flex mb-4">
                      <div className="me-4 text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          fill="currentColor"
                          className="bi bi-geo"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className="mb-3">Address</h4>
                        <address className="mb-0 text-secondary">
                          Office No. 1226-27, RK World Tower, 150 Feet Ring Rd,
                          Sheetal Park, Rajkot, Gujarat 360005, India
                        </address>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div className="me-4 text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          fill="currentColor"
                          className="bi bi-telephone-outbound"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </div>
                      <div>
                        <h4 className="mb-3">Phone</h4>
                        <p className="mb-0">
                          <a
                            className="link-secondary text-decoration-none"
                            href="tel:+15057922430"
                          >
                            +919033375852
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="me-4 text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          fill="currentColor"
                          className="bi bi-envelope-at"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                          <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                        </svg>
                      </div>
                      <div>
                        <h4 className="mb-3">E-Mail</h4>
                        <p>
                          <a
                            className="link-secondary text-decoration-none"
                            href="mailto:demo@yourdomain.com"
                          >
                            sales@orendafinserv.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Footer 2 - Bootstrap Brain Component */}
      <footer className="footer">
        {/* Widgets - Bootstrap Brain Component */}
        <section className="bg-light py-4 py-md-5 py-xl-8 border-top border-light">
          <div className="container overflow-hidden">
            <div className="row gy-4 gy-lg-0 justify-content-xl-between">
              {/* <div className="col-12 col-md-4 col-lg-3 col-xl-2"> */}
              {/* <div className="widget"> */}
              {/* <a href="#!"> */}
              {/* <img style={{ width: '200px', height: 'auto' }} src={companyLogo} alt="Company logo" className="footer-logo" /> */}

              {/* </a> */}
              {/* </div> */}
              {/* </div> */}
              {/* <div className="col-12 col-md-4 col-lg-3 col-xl-2"> */}
              {/* <div className="widget"> */}
              {/* <h4 className="widget-title mb-4">Get in Touch</h4> */}
              {/* <address className="mb-4">Office No. 1226-27, RK World Tower, 150 Feet Ring Rd, Sheetal Park, Rajkot, Gujarat 360005, India</address> */}
              {/* <p className="mb-1"> */}
              {/* <a className="link-secondary text-decoration-none" href="tel:+15057922430">+919033375852</a> */}
              {/* </p> */}
              {/* <p className="mb-0"> */}
              {/* <a className="link-secondary text-decoration-none" href="mailto:demo@yourdomain.com">sales@orendafinserv.com</a> */}
              {/* </p> */}
              {/* </div> */}
              {/* </div> */}
              <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                <div className="widget">
                  <h4 className="widget-title mb-4">Learn More</h4>
                  <ul className="list-unstyled">
                    {/* <li class="mb-2">
              <a href="#!" class="link-secondary text-decoration-none">About</a>
            </li>
            <li class="mb-2">
              <a href="#!" class="link-secondary text-decoration-none">Contact</a>
            </li>
            <li class="mb-2">
              <a href="#!" class="link-secondary text-decoration-none">Advertise</a>
            </li> */}
                    <li className="mb-2">
                      <a
                        href="/terms"
                        className="link-secondary text-decoration-none"
                      >
                        Terms of Service
                      </a>
                    </li>
                    <li className="mb-0">
                      <a
                        href="/privacy"
                        className="link-secondary text-decoration-none"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-xl-4">
                <div className="widget">
                  {/* <h4 class="widget-title mb-4">Our Newsletter</h4> */}
                  {/* <p class="mb-4">Subscribe to our newsletter to get our news & discounts delivered to you.</p> */}
                  <div style={{ width: "100%" }}>
                    <iframe
                      width="100%"
                      height={300}
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Office%20No.%201226-27,%20RK%20World%20Tower,%20Shital%20Park%20Chowk,%20150%20Feet%20Ring%20Rd,%20Sheetal%20Park,%20Manharpura%201,%20Dharam%20Nagar%20Society,%20Rajkot,%20Gujarat%20360005,%20India+(Orenda%20Finserv)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    >
                      &lt;a href="https://www.gps.ie/"&gt;gps devices&lt;/a&gt;
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Copyright - Bootstrap Brain Component */}
        <div className="bg-light py-4 py-md-5 py-xl-8 border-top border-light-subtle">
          <div className="container overflow-hidden">
            <div className="row gy-4 gy-md-0">
              <div className="col-xs-12 col-md-7 order-1 order-md-0">
                <div className="copyright text-center text-md-start">
                  © 2024. All Rights Reserved.
                </div>
                <div className="credits text-secondary text-center text-md-start mt-2 fs-7">
                  Built by{" "}
                  <a className="link-secondary text-decoration-none">Orenda</a>{" "}
                  with <span className="text-primary">♥</span>
                </div>
              </div>
              <div className="col-xs-12 col-md-5 order-0 order-md-1">
                <ul className="nav justify-content-center justify-content-md-end">
                  <li className="nav-item">
                    <a
                      className="nav-link link-dark"
                      href="https://www.facebook.com/orendafinserv/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        className="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-dark"
                      href="https://in.linkedin.com/company/orenda-finserv-llp"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-linkedin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                      </svg>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                  <a className="nav-link link-dark" href="#!">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link link-dark"
                      href="https://www.instagram.com/orendafinserv/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        className="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Javascript Files: Vendors */}
      {/* Javascript Files: Controllers */}
      {/* BSB Body End */}
    </div>
  );
}

export default App;
